import React, { useEffect } from 'react'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import { Typography } from '@local/shared/components/System'
import Box from '@local/shared/components/System/Box'
import { createFunctionWithTimeout } from 'src/util'

const FORM_ID = '1FAIpQLSdzOhYZRB6Q18ghXA-N2rg1YtmmDQDk-f9EqYwEL5Nr7_q7Dw'

const ThankYouPage = ({ location }) => {
  const afterPageLoad = () => {
    const name = encodeURIComponent(location.state['full-name'])
    const email = encodeURIComponent(location.state['email'])
    window.location.href = `https://docs.google.com/forms/d/e/${FORM_ID}/viewform?usp=pp_url&entry.2005620554=${name}&entry.1045781291=${email}`
  }

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-666833645/A45OCP_X-8kBEO2l_L0C',
        event_callback: createFunctionWithTimeout(afterPageLoad),
      })
    typeof window !== 'undefined' && !window.gtag && afterPageLoad()
  }, [])
  return (
    <PageLayout>
      <Seo title="Thank You" />
      <Box
        minHeight={'30rem'}
        bgcolor={'blue.light0'}
        display={'flex'}
        justifyContent={'center'}
        textAlight={'left'}
        py={'4rem'}
        px={'2rem'}
      >
        <Box maxWidth={'80rem'}>
          <Typography variant={'h1'}>Thank you!</Typography>
          <Typography variant={'subtitle'}>
            Thank you! You're being redirected!
          </Typography>
        </Box>
      </Box>
    </PageLayout>
  )
}

export default ThankYouPage
